import { render, staticRenderFns } from "./ClinicsLocations.vue?vue&type=template&id=0ddfe9fc&scoped=true&lang=html&"
import script from "./ClinicsLocations.vue?vue&type=script&lang=js&"
export * from "./ClinicsLocations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ddfe9fc",
  null
  
)

export default component.exports